<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'

const config = {
	options: {
		resource: 'alert-types',
		schemaModifier: async data => {
			//	remove this line if need to support bulk
			data = data.items
			return data
		}
	}
}

export default {
	name: 'AlertTypesNew',
	components: { NewPageGen },
	data: () => config
}
</script>
